import { claimer } from '@/services/service';
const endPoint = 'dashboard/counts';


export const getDashboardData = async () => {
  try {
    const response = await claimer(endPoint);
    console.log('getDashboardData:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
  }
};

