// crops.js

import { addCrop } from '@/services/cropsService'; 

const state = {
  crops: [], // This array will store the list of crops
};

const mutations = {
  SET_CROPS(state, crops) {
    state.crops = crops;
  },
  ADD_CROP(state, crop) {
    state.crops.push(crop);
  },
  // Add other mutations as needed
};

const actions = {
  async fetchCrops({ commit }) {
    try {
      // Fetch crops from the service or API
      // const crops = await getCrops(); 
      commit('SET_CROPS', crops);
    } catch (error) {
      console.error('Error fetching crops:', error);
      throw error;
    }
  },

  async addCrop({ commit }, cropData) {
    try {
      // Add crop to the service or API
      const newCrop = await addCrop(cropData); 
      commit('ADD_CROP', newCrop);
    } catch (error) {
      console.error('Error adding crop:', error);
      throw error;
    }
  },
  // Add other actions as needed
};

const getters = {
  getCrops: (state) => state.crops,
  // Add other getters as needed
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
