import { sender, claimer, update, deleteData } from '@/services/service';
const endPoint = 'user';

export const getUser = async () => {
  try {
    const response = await claimer(endPoint+'/profile');
    console.log('getUser:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
    throw error;
  }
};
export const updateUser = async (data) => {
  try {
    const response = await update(endPoint+'/update', data);
    console.log('updateUser:', response);
    return response;
  } catch (error) {
    console.error('Error updating data:', endPoint, error);
    throw error;
  }
};
export const updateCredential = async (data) => {
  try {
    const response = await update(endPoint+'/update-email-phone', data);
    console.log('updateCredential:', response);
    return response;
  } catch (error) {
    console.error('Error updating data:', endPoint, error);
    throw error;
  }
};
export const updatePassword = async (data) => {
  try {
    const response = await update(endPoint+'/update-password', data);
    console.log('updatePassword:', response);
    return response;
  } catch (error) {
    console.error('Error updating data:', endPoint, error);
    throw error;
  }
};
export const getDataByEmailOrPhone = async (payload) => {
  try {
    const response = await sender(endPoint+'/'+payload);
    console.log('getDataByEmailOrPhone:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
    throw error;
  }
};