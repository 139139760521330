import { sender, claimer, update, deleteData } from '@/services/service';
const endPoint = 'crops';
export const addCrop = async (cropData) => {
  try {
    // Assuming the API endpoint for adding a crop is '/api/crops'
    const response = await sender(endPoint, cropData);
    return response; // Assuming the response contains the added crop data
  } catch (error) {
    console.error('Error adding crop:', error);
    throw error;
  }
};

export const getCrop = async (payload) => {
  try {
    const response = await claimer(endPoint, payload);
    console.log('getCrop:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
  }
};

export const getCropById = async (payload) => {
  try {
    const response = await claimer(endPoint + '/' + payload);
    console.log('getCropById:', response);
    return response;
  } catch (error) {
    console.error('Error fetching Single Crop data:', endPoint, error);
  }
};

export const editCrop = async (payload) => {
  try {
    const response = await update(endPoint+"/"+payload.icrop_id, payload);
    console.log('editCrop:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
  }
};
export const deleteCropById = async (payload) => {
  try {
    const response = await deleteData(endPoint+"/"+payload.crop_id, payload);
    console.log('editCrop:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
  }
};

export const searchLiveCrops = async (payload) => {
  try {
    const response = await claimer(endPoint+"/"+"search", payload);
    console.log('searchLiveCrops:', response);
    return response;
  } catch (error) {
    console.error('Error fetching data:', endPoint, error);
  }
};