import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/AboutView.vue'),
    // meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/experiments/add',
    name: 'experiments',
    component: () => import( '../views/experimentView/Experiments.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/experiments/update/:id',
    name: 'experimentsUpdate',
    component: () => import( '../views/experimentView/Experiments.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/experiments/record-data/:id',
    name: 'experimentRecordData',
    component: () => import( '../views/experimentView/ExperimentRecordData.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/experiment-list',
    name: 'experimentList',
    component: () => import( '../views/experimentView/ExperimentList.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/crops-list',
    name: 'cropsList',
    component: () => import( '../views/cropsView/CropsList.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/crops/add',
    name: 'crops',
    component: () => import( '../views/cropsView/Crops.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/crops/update/:id',
    name: 'cropsUpdate',
    component: () => import( '../views/cropsView/Crops.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/disease/add',
    name: 'disease',
    component: () => import( '../views/diseaseView/Disease.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/disease/update/:id',
    name: 'diseaseUpdate',
    component: () => import( '../views/diseaseView/Disease.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/disease-list',
    name: 'diseaseList',
    component: () => import( '../views/diseaseView/DiseaseList.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/observation-list',
    name: 'observationList',
    component: () => import( '../views/observationView/ObservationList.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/observation/add',
    name: 'observation',
    component: () => import( '../views/observationView/Observation.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/observation/update/:id',
    name: 'observationUpdate',
    component: () => import( '../views/observationView/Observation.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/record-data-list/:id',
    name: 'recordDataList',
    component: () => import( '../views/recordDataView/RecordDataList.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/record-data/:id',
    name: 'recordData',
    component: () => import( '../views/recordDataView/RecordData.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/record-data/:id/record-data/:dataId',
    name: 'recordDataUpdate',
    component: () => import( '../views/recordDataView/RecordData.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/record-data/:id/:name',
    name: 'recordDataExp',
    component: () => import( '../views/recordDataView/RecordData.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import( '../views/profileView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/Register.vue'),
  },
  {
    path: '*',
    component: () => import( '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      localStorage.clear();
      next({ name: 'about' });
    } else {
      const loginTime = JSON.parse(localStorage.getItem('login-time'));
      const currentTime = Date.now();
      if (loginTime != null) {
        const timeDifferenceMillis = currentTime - loginTime;
        // Convert milliseconds to minutes
        const timeDifferenceMinutes = timeDifferenceMillis / (1000 * 60);
        console.log("timeDifferenceMinutes: ", timeDifferenceMinutes);
        if (timeDifferenceMinutes <= 60) {
          next();
        } else {
          localStorage.clear();
          next({ name: 'login' });
        }
      } else {
        localStorage.clear();
        next({ name: 'about' });
      }
    }
  } else {
    next();
  }
});


export default router
