const errorMessagePopUpMixin = {
  methods: {
    actionFailed(error) {
      console.log('error====>', error);
      if (error.response) {
        if (error.response.status === 400) {
          this.$swal({
            icon: 'error',
            title: error.response.data.message,
            timer: 1500,
          });
        } else if (error.response.status === 404) {
          this.$swal({
            icon: 'error',
            title: 'Resource not found',
            timer: 1500,
          });
        } else if (error.response.status === 422) {
          this.$swal({
            icon: 'error',
            title: 'Invalid Data',
            timer: 1500,
          });
        } else if (error.response.status === 500) {
          this.$swal({
            icon: 'error',
            title: 'Internal Server Error',
            timer: 1500,
          });
        } else {
          this.$swal({
            icon: 'error',
            title: 'An Error Occurred!',
            timer: 1500,
          });
        }
      }else {
        this.$swal({
          icon: 'error',
          title: 'An Error Occurred!',
          timer: 1500,
        });
      }
    },
  },
};
export default errorMessagePopUpMixin;
