<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="($store.state.isLoggedIn || !!isLoggedIn) && ($route.name !== 'login' && $route.name !== 'register') "
      v-model="drawer"
      app
    >
      <v-list-item :to="'/'">
        <!-- <v-list-item-icon>
          <v-img
            lazy-src="./assets/logo.png"
            max-height="50"
            max-width="50"
            src="./assets/logo.png"
          ></v-img>
        </v-list-item-icon> -->
        <v-list-item-content class="py-5">
          <v-list-item-title class="font-weight-black">BINA Research Note Book</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="item in items" >
          <v-list-item :key="item.id" v-if="!item.child" :to="item.to" link>
            <v-list-item-icon>
              <v-icon class="font-weight-black">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black" :class="[item.id===7?'caption':'']">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- @click="()=>$router.push(item.to)" -->
          <!-- :to="item.to" -->
          <v-list-group v-else no-action >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon class="font-weight-black">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-black">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="childItem in item.child"
              :key="childItem.id"
              :to="childItem.to"
              link
            >
              <v-list-item-icon>
                <v-icon class="font-weight-black">{{ childItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-black">{{ childItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon class="font-weight-black">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-weight-black">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="(($store.state.isLoggedIn || !!isLoggedIn) && ($route.name !== 'login' && $route.name !== 'register')) ||$route.name === 'about' "
      app
      color="light-green darken-3"
      dark
      
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>BINA Research Note Book</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="($store.state.isLoggedIn || !!isLoggedIn)"  bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="brown" size="48">
              <span class="white--text text-h5">{{ $store.state.user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="brown">
                <span class="white--text text-h5">{{ $store.state.user.initials }}</span>
              </v-avatar>
              <h3>{{ $store.state.user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ $store.state.user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn :to="{ name: 'profile' }" depressed rounded text> Edit Account </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="logout()">
                <v-icon>mdi-logout</v-icon> Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
      <v-btn
        v-else
        outlined
        :to="{ name: 'login' }"
      >
        <v-icon>mdi-login</v-icon> Login
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { getUser } from '@/services/userService';
export default {
  data() {
    return {
      drawer: null,
      items: [
        { id:7,title: 'BINA Research Note Book', icon: 'mdi-react', to: '/' },
        { id:1, title: 'Dashboard', icon: 'mdi-format-list-checks', to: {name:'dashboard'} },
        {
          id:2,
          title: 'Experiment',
          icon: 'mdi-flask-outline',
          to: { name: 'experimentList' },
          child: [
            {
              id:21,
              title: 'Experiment List',
              icon: 'mdi-list-box-outline',
              to: { name: 'experimentList' },
            },
            {
              id:22,
              title: 'Add Experiment',
              icon: 'mdi-tab-plus',
              to: { name: 'experiments'},
            },
            
          ],
        },
        {
          id:3,
          title: 'Crops',
          icon: 'mdi-sprout-outline',
          to: { name: 'cropsList' },
          child: [
            {
              id:31,
              title: 'Crops List',
              icon: 'mdi-list-box-outline',
              to: { name: 'cropsList' },
            },
            {
              id:32,
              title: 'Add Crops',
              icon: 'mdi-tab-plus',
              to: { name: 'crops' },
            },
            
          ],
        },
        {
          id:4,
          title: 'Diseases',
          icon: 'mdi-space-invaders',
          to: { name: 'diseaseList' },
          child: [
            {
              id:41,
              title: 'Disease List',
              icon: 'mdi-list-box-outline',
              to: { name: 'diseaseList' },
            },
            {
              id:42,
              title: 'Add Disease',
              icon: 'mdi-tab-plus',
              to: { name: 'disease'},
            },
            
          ],
        },
        {
          id:5,
          title: 'Observation',
          icon: 'mdi-magnify-scan',
          to: { name: 'observationList' },
          child: [
            {
              id:51,
              title: 'Observation List',
              icon: 'mdi-list-box-outline',
              to: { name: 'observationList' },
            },
            {
              id:52,
              title: 'Add Observation',
              icon: 'mdi-tab-plus',
              to: { name: 'observation' },
            },
            
          ],
        },
        { id:6,title: 'Record Data', icon: 'mdi-folder-file-outline', to: { name: 'recordData', params: { id: 'add' } } },
        
        // { title: 'Register', icon: 'mdi-help-box', to: '/register' },
      ],
      // user: {
      //   initials: 'JD',
      //   fullName: 'John Doe',
      //   email: 'john.doe@doe.com',
      // },
    };
  },
  computed: {
    isLoggedIn() {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (isLoggedIn) {
        this.getUserProfile();
      }
      return !!localStorage.getItem('isLoggedIn');
    },
  },
  mounted() {
    
  },
  methods: {
    async getUserProfile() {
      try {
        this.$store.state.user = {
          _id: null,
          initials: '',
          fullName: '',
          email: '',
          organization: '',
          designation: '',
          phoneNumber: '',
          isStaff: false,
        };
        const response = await getUser();
        if (response) {
          this.$store.state.user = {
            _id: response._id,
            initials: response.full_name?response.full_name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase():'JD',
            fullName: response.full_name?response.full_name:'John Doe',
            email: response.email,
            organization: response.organization,
            designation: response.designation,
            phoneNumber: response.phone_number,
            isStaff: response.is_staff,
          }
        }
      } catch (error) {
        console.error('Error loading User Data:', error);
      }
    },
    logout() {
      localStorage.clear();
      window.location.reload();
    },
    reset(){
      this.$store.state.user = {
        initials: '',
        fullName: '',
        email: '',
      }
    },
    
  },
};
</script>
<style>


tr td, tr th{
  text-align: left !important;
  font-size: 1.1rem !important ;
}
/* tr td span{
  font-size: .9rem !important ;
} */
</style>
<style scoped>
.v-list--dense .v-list-item .v-list-item__title{
  font-size: 0.9125rem;
}
</style>
