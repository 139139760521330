import axios from 'axios';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});
const httpLogin = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

const authService = {
  register: async (userData) => {
    return authService.post('user/register', userData);
  },

  login: async (credentials) => {
    return authService.postLogin('user/login', credentials);
  },
  // Private method, not exported
  postLogin: async (endpoint, data) => {
    try {
      const response = await httpLogin.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(`Error during POST request to ${endpoint}:`, error);
      throw error;
    }
  },
  post: async (endpoint, data) => {
    try {
      const response = await http.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(`Error during POST request to ${endpoint}:`, error);
      throw error;
    }
  },
  
  
};

export default authService;
