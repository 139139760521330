<template>
  <v-container class="dashboard-container">
    <!-- Dashboard Header -->
    <v-row>
      <v-col>
        <v-subheader class="text-h5">Research Dashboard</v-subheader>
      </v-col>
    </v-row>

    <!-- Summary Cards -->
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-flask-outline</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Experiments</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about the experiment go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='activeExperimentsCount' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="light-green darken-2"  :to="{ name: 'experimentList' }" text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-sprout-outline</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Crops</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about the crops go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='totalCropsCount' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue lighten-2" :to="{ name: 'cropsList' }" text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-space-invaders</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Diseases</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about the diseases go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='diseasesCount' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="deep-purple lighten-2" :to="{ name: 'diseaseList' }" text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-magnify-scan</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Observation</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about Observation go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='observation' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <!-- :to="{ name: 'cropsList' }" -->
            <v-btn color="lime lighten-2" :to="{ name: 'observationList' }" text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-folder-file-outline</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Record Data</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about the Record Data go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='recordData' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <!-- :to="{ name: 'diseaseList' }" -->
            <v-btn :to="{ name: 'recordData', params: { id: 'add' } }" color="deep-purple lighten-2"  text >Create One</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-account-supervisor-outline</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Co-workers/Students</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about Co-workers/Students go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <span class="experiment-number"><countTo :startVal='0' :endVal='workers' :duration='3000'></countTo></span>
          </v-card-text>
          <v-card-actions>
            <!-- :to="{ name: 'cropsList' }" -->
            <v-btn color="blue lighten-2" style="cursor: no-drop; " text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="experiment-card" style=" background: rgba(128, 128, 128, 0.285); cursor: no-drop; ">
          <v-row>
            <v-col cols="2" sm="2" md="2" lg="2"><v-icon class="icon">mdi-chart-line</v-icon></v-col>
            <v-col class="card-title pt-5" cols="10" sm="10" md="10" lg="10">Analysis Tools</v-col>
          </v-row>
          <v-card-subtitle class="adjust text-body-2">Details about the Analysis Tools go here.</v-card-subtitle>
          <!-- Add your experiment details or content here -->
          <v-card-text>
            <br>
            <!-- <span class="experiment-number">{{ diseasesCount }}</span> -->
          </v-card-text>
          <v-card-actions>
            <!-- :to="{ name: 'diseaseList' }" -->
            <v-btn color="deep-purple lighten-2" style="cursor: no-drop; "  text >More Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getDashboardData } from '@/services/dashboardService';
import errorMessagePopUpMixin from '@/mixins/errorMessagePopUp';
import countTo from 'vue-count-to';
export default {
  mixins: [errorMessagePopUpMixin],
  components: { countTo },
  data() {
    return {
      activeExperimentsCount: 0,
      totalCropsCount: 0,
      diseasesCount: 0,
      observation: 0,
      recordData: 0,
      workers: 0,
    };
  },
  mounted() {
    this.$store.dispatch('setLoggedIn')
    this.getDataForDashboard();
  },
  methods: {
    async getDataForDashboard() {
      try {
        this.activeExperimentsCount = 0;
        this.totalCropsCount = 0;
        this.diseasesCount = 0;
        this.observation = 0;
        const response = await getDashboardData();
        console.log('getDataForDashboard:', response);
        if (response) {
          this.activeExperimentsCount = response.experiment;
          this.totalCropsCount = response.crops;
          this.diseasesCount = response.disease;
          this.observation = response.observation;
          this.recordData = response.record_data;
          this.workers = response.workers;
        }
      } catch (error) {
        this.actionFailed(error);
        console.error('Error in fetching data:', error);
      }
    },
  },
};
</script>
<style scoped>
.dashboard-container {
  padding: 20px;
}
.experiment-card {
  width: 300px;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.adjust{
  height: 70px;
}
.icon-col {
  width: 20%;
}

.icon {
  font-size: 36px;
  color: #2196F3; /* Adjust the icon color as needed */
}

.title-col {
  width: 80%;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #4CAF50; /* Adjust the title color as needed */
}
.experiment-number {
  display: block;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  color: #FFC107;
}
.experiment-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>




