import axios from 'axios';
import Router from '@/router/index';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getAuthHeaders = () => {
  const token = JSON.parse(localStorage.getItem('key'));
  const tokenType = JSON.parse(localStorage.getItem('tokenType'));
  
  return token && tokenType ? { Authorization: `${tokenType} ${token}` } : {};
};

const handleLoginCheck = async () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    localStorage.clear();
    Router.push({ name: 'login' });
    throw new Error('Not logged in');
  }

  const loginTime = JSON.parse(localStorage.getItem('login-time'));
  if (loginTime == null) {
    localStorage.clear();
    Router.push({ name: 'login' });
    throw new Error('Login time not found');
  }

  const currentTime = Date.now();
  const timeDifferenceMinutes = (currentTime - loginTime) / (1000 * 60);
  console.log("timeDifferenceMinutes: ", timeDifferenceMinutes);

  if (timeDifferenceMinutes > 50) {
    const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
    if (!refresh_token) {
      localStorage.clear();
      Router.push({ name: 'login' });
      throw new Error('Refresh token not found');
    }
    
    const response = await http.post('user/refresh', refresh_token , {
      headers: { 'Content-Type': 'application/json' },
    });
    
    if (!response.data) {
      localStorage.clear();
      Router.push({ name: 'login' });
      throw new Error('Failed to refresh token');
    }

    localStorage.setItem('key', JSON.stringify(response.data.access_token));
    localStorage.setItem('tokenType', JSON.stringify(response.data.token_type));
    localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token));
    localStorage.setItem('login-time', JSON.stringify(Date.now()));
  }
};

const sendRequest = async (method, endpoint, data = null) => {
  try {
    await handleLoginCheck();
    const headers = getAuthHeaders();
    if (headers) {
      const config = { headers };

      if (method === 'get' || method === 'delete') {
        config.params = data;
      }
      const response = method === 'get' || method === 'delete'
        ? await http[method](endpoint, config)
        : await http[method](endpoint, data, config);

      return response.data;
    }
    
  } catch (error) {
    console.error(`Error during ${method.toUpperCase()} request to ${endpoint}:`, error.response?.data || error.message);
    throw error;
  }
};

export const sender = (endpoint, data) => sendRequest('post', endpoint, data);
export const update = (endpoint, data) => sendRequest('patch', endpoint, data);
export const upgrade = (endpoint, data) => sendRequest('put', endpoint, data);
export const deleteData = (endpoint, data = null) => sendRequest('delete', endpoint, data);
export const claimer = (endpoint, data = null) => sendRequest('get', endpoint, data);
