import Vue from 'vue';
import Vuex from 'vuex';
import authService from '@/services/authService';
import cropsModule from './modules/crops';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {
      _id: null,
      initials: '',
      fullName: '',
      email: '',
      organization: '',
      designation: '',
      phoneNumber: '',
      isStaff: false,
    },
  },
  mutations: {
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
  },
  actions: {
    setLoggedIn({ commit }){
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      console.log('SET_IS_LOGGED_IN', !!isLoggedIn, localStorage.getItem('isLoggedIn'));
      commit('SET_IS_LOGGED_IN', !!isLoggedIn);
    },
    async login({ commit }, credentials) {
      try {
        // Call the login service
        const response = await authService.login(credentials);

        // Assuming the response indicates a successful login
        // You can adjust this based on your actual response format
        if (response.success) {
          // Update the state to indicate the user is logged in
          commit('SET_IS_LOGGED_IN', true);
          
        }

        // Return the response for further handling if needed
        return response;
      } catch (error) {
        // Handle login error
        console.error('Login error:', error);
        throw error;
      }
    },
    async register({ commit }, userData) {
      try {
        const response = await authService.register(userData);
        if (response.success) {
          commit('SET_IS_LOGGED_IN', true);
        }
        return response;
      } catch (error) {
        console.error('Registration error:', error);
        throw error;
      }
    },
  },
  getters: {},
  modules: {
    crops: cropsModule,
  },
});
